import React from 'react';
import styles from './styles.module.css';

import { ArrowButton } from 'src/components/ArrowButton';
import { Checkbox } from 'src/components/Checkbox/component';
import { LogicRulePuzzleQuestionSpec } from '../../index';
import { Tooltip } from 'src/components/Tooltip';

interface Props {
	currQuestionNum: number;
	questionTotal: number;
	nextQuestion: (answer?: string | undefined) => void;
	currQuestionSpec: LogicRulePuzzleQuestionSpec;
}

interface State {
	checked: Array<boolean>;
	anyChecked: boolean;
}

export class Questions extends React.Component<Props, State> {
	state = {
		checked: [false],
		anyChecked: false,
	};

	constructor(props: Props) {
		super(props);
	}

	toggleChecked = (id: number) => () => {
		let checked = [...this.state.checked];
		checked[id] = !checked[id];
		this.setState({ checked, anyChecked: checked.includes(true) });
	};

	render() {
		let optionIDs = Array<String>();
		const skipTooltip = `Submits this question with no answer. The question cannot be attempted later.`;
		return (
			<div className={styles.questionsContainer}>
				<h4 className={styles.header4}>{this.props.currQuestionSpec.headerText}</h4>
				{this.props.currQuestionSpec.logicRules.map(
					(logicRule: { logicCategory: string; logicRules: string }) => {
						return (
							<>
								<h6 className={styles.header6}>{logicRule.logicCategory}</h6>
								<p className={styles.logicRule}>{logicRule.logicRules}</p>
							</>
						);
					}
				)}
				<h4 className={styles.header4}>{this.props.currQuestionSpec.instructionText}</h4>
				<div>
					{this.props.currQuestionSpec.questions.map(
						(questionDetails: { questionText: string; options: any }) => {
							let optionsCount = Object.keys(questionDetails.options).length;
							let counter: number;
							for (counter = this.state.checked.length; counter < optionsCount; counter++) {
								this.state.checked.push(false);
							}
							return (
								<>
									<p className={styles.header6}>{questionDetails.questionText}</p>
									{questionDetails.options.map(
										(option: { optionId: string; optionText: string }, j: number) => {
											optionIDs[j] = option.optionId;
											return (
												<div className={styles.checkboxQuestions}>
													<Checkbox
														caption={option.optionText}
														checked={this.state.checked[j]}
														key={option.optionId}
														onChecked={this.toggleChecked(j)}
													/>
												</div>
											);
										}
									)}
								</>
							);
						}
					)}
				</div>
				<div className={styles.submitContainer}>
					<ArrowButton
						caption="Submit Answer"
						onClick={() => {
							this.props.nextQuestion(JSON.stringify(this.state.checked));
							this.setState({ checked: [], anyChecked: false });
						}}
						isDisabled={!this.state.anyChecked}
					/>

					<Tooltip caption={skipTooltip}>
						<button
							className={styles.skipButton}
							onClick={() => {
								this.setState({ checked: [], anyChecked: false });
								this.props.nextQuestion();
							}}
						>
							Skip &gt;
						</button>
					</Tooltip>
				</div>
			</div>
		);
	}
}
