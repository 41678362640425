import React from 'react';
import styles from './styles.module.css';

import { Layout } from 'src/components/Layout';

import { Questions } from './components/Questions';
import { PuzzleComplete } from 'src/components/PuzzleComplete';
import { ProgressBar } from 'src/components/ProgressBar';
import { Spinner } from 'src/components/Spinner';

import { PuzzleSessionManager } from 'src/helpers/PuzzleSessionManager/';
import {
	progressBarQuestionsFromSessionQuestions,
	getNextQuestion,
} from 'src/helpers/PuzzleSessionManager/QuestionUtilities';

import { LocationTestProps } from 'src/model/LocationTestProps.interface';
import { TestState } from 'src/model/TestState.interface';
import { signInAsGuestUserIfRequired } from 'src/helpers/UserManager';
import { navigate } from 'gatsby';
import { isSignUpRequired } from 'src/helpers/SignUpThresholdHelper';

export interface LogicRulePuzzleQuestionSpec {
	headerText: string;
	logicRules: [
		{ logicCategoryId: number; logicCategory: string; logicRules: string },
		{ logicCategoryId: number; logicCategory: string; logicRules: string }
	];
	instructionText: string;
	questions: [
		{
			questionText: string;
			options: [
				{
					optionId: string;
					optionText: string;
				},
				{
					optionId: string; //Handle 2-4 choices
					optionText: string;
				},
				{
					optionId?: string;
					optionText?: string;
				},
				{
					optionId?: string;
					optionText?: string;
				}
			];
		}
	];
}

export default class LogicRulePuzzle extends React.Component<LocationTestProps, TestState> {
	state: TestState = {
		currentQuestionNum: 0,
		questions: [],
		isLoaded: false,
		sessionQuestionId: undefined,
		sessionId: undefined,
		lastSessionId: undefined,
		puzzleIsComplete: false,
		error: false,
		currentQuestionSpec: null,
		acquiredSkills: [],
	};

	async componentDidMount() {
		const puzzle = this.props.pageContext.puzzleData;
		await signInAsGuestUserIfRequired();
		const showCreateAccount: boolean = await isSignUpRequired();
		if (showCreateAccount) {
			navigate('/sign-up-required');
		} else {
			// TODO Is sessionId ever set?  This approach is used across all puzzle pages.
			let { lastSessionId } = this.state;
			const response = await getNextQuestion(puzzle.id, this.state.sessionId);
			if (response.sessionId) lastSessionId = response.sessionId;
			this.setState({ lastSessionId });
			this.setState(response);
		}
	}

	render() {
		const {
			isLoaded,
			questions,
			puzzleIsComplete,
			currentQuestionNum,
			currentQuestionSpec,
			error,
			sessionQuestionId,
			acquiredSkills,
		} = this.state;

		const progressBarQuestions = progressBarQuestionsFromSessionQuestions(
			questions,
			currentQuestionNum
		);

		const puzzle = this.props.pageContext.puzzleData;

		const decisionTableQuestionSpec = currentQuestionSpec as LogicRulePuzzleQuestionSpec;

		return (
			<Layout>
				<div className={styles.container}>
					{error ? (
						<div>An error has occurred</div>
					) : (
						<>
							{isLoaded && questions ? (
								<>
									{!puzzleIsComplete ? (
										<>
											<h1>{puzzle.title}</h1>
											<div className={styles.subContainer}>
												<ProgressBar questions={progressBarQuestions} />
												<Questions
													currQuestionNum={currentQuestionNum}
													currQuestionSpec={decisionTableQuestionSpec}
													questionTotal={questions.length}
													nextQuestion={async (answerValue?: string) => {
														// call to API
														if (sessionQuestionId) {
															await PuzzleSessionManager.answerQuestion(
																sessionQuestionId,
																answerValue,
																true
															);

															const response = await getNextQuestion(
																this.props.pageContext.puzzleData.id,
																this.state.sessionId
															);
															this.setState(response);
														} else {
															console.error(`No sessionQuestionId being passed to nextQuestion`);
														}
													}}
												/>
											</div>
										</>
									) : (
										<PuzzleComplete
											skills={acquiredSkills}
											puzzleSessionId={this.state.lastSessionId ? this.state.lastSessionId : 0}
										/>
									)}
								</>
							) : (
								<Spinner caption="Loading Puzzle" />
							)}
						</>
					)}
				</div>
			</Layout>
		);
	}
}
